export const fields = [
  //{value: 'assetNumber', label: 'Asset ID'},
  {value: 'assetDescription', label: 'Asset Name'},
  {value: 'status', label: 'Status'},
  {value: 'location', label: 'Location Premium'},
  {value: 'address', label: 'Address'},
  {value: 'suburb', label: 'Suburb'},
  {value: 'stateCode', label: 'State'},
  // {value: 'id', label: 'ID'},
  // {value: 'fulcrumId', label: 'Fulcrum ID'},
  //{value: 'projectId', label: 'Project ID'},
  {value: 'locationCodeDesc', label: 'Location Code Desc'},
  {value: 'assetClass', label: 'Asset Class'},
  {value: 'assetType', label: 'Asset Type'},
  {value: 'assetSubType', label: 'Asset SubType'},
  {value: 'australisClassification', label: 'Australis Classification'},
  {value: 'asbestosStatus', label: 'Asbestos Status'},
  {value: 'valuationDate', label: 'Valuation Date', type: 'Date'},
  {value: 'valuationType', label: 'Valuation Type'},
  {value: 'insuranceValuation', label: 'Insurance Valuation'},
  {value: 'marketApproach', label: 'Market Approach'},
  {value: 'inspectionDate', label: 'Inspection Date', type: 'Date'},
  {
    value: 'inspector',
    label: 'Inspector',
    note: 'Note that only valid data is the added Inspector(s) in the project.',
  },
  {value: 'mainQuantity', label: 'Main Quantity', type: 'Number'},
  {value: 'secondaryQuantity', label: 'Secondary Quantity', type: 'Number'},
  {value: 'localGovernmentArea', label: 'Local Government Area'},
  {value: 'locationPremiumAdjustment', label: 'Location Premium Adjustment', type: 'Number'},
  {value: 'timeIndex', label: 'Time Index', type: 'Number'},
  {value: 'distanceFromRoad', label: 'Distance From Road'},
  {value: 'constructionAndSiteFactors', label: 'Site Factors'},
  {
    value: 'hotWaterSystem',
    label: 'Hot Water System',
    note: 'Note that only valid data is "yes" or "no" ',
  },
  {
    value: 'hotWaterSystemType',
    label: 'Hot Water System Type',
    note: 'Note that only valid data is the pre-defined values of Hot Water System Type.',
  },
  {value: 'hvac', label: 'HVAC', note: 'Note that only valid data is "yes" or "no" '},
  {
    value: 'hvacType',
    label: 'HVAC Type',
    note: 'Note that only valid data is the pre-defined values of HVAC Type',
  },
  {value: 'ductedAcCoverage', label: 'Ducted AC Coverage'},
  {value: 'countOfAcUnits', label: 'Count Of AC Units'},
  {value: 'solar', label: 'Solar', note: 'Note that only valid data is "yes" or "no" '},
  {
    value: 'solarSystemSize',
    label: 'Solar System Total Size',
    note: 'Note that only valid data is the pre-defined values of Solar.',
  },
  {value: 'waterTank', label: 'Water Tank', note: 'Note that only valid data is "yes" or "no" '},
  {
    value: 'waterTankTotalSize',
    label: 'Water Tank Total Size',
    note: 'Note that only valid data is the pre-defined values of Water Tank.',
  },
  {value: 'tankStand', label: 'Tank Stand', note: 'Note that only valid data is "yes" or "no" '},
  {
    value: 'tankStandSize',
    label: 'Tank Stand Size',
    note: 'Note that only valid data is the pre-defined values of Tank Stand.',
  },
  {
    value: 'septicSystem',
    label: 'Septic System',
    note: 'Note that only valid data is "yes" or "no" ',
  },
  {
    value: 'septicSysteType',
    label: 'Septic System Type',
    note: 'Note that only valid data is the pre-defined values of Septic System Type.',
  },
  {
    value: 'fireServices',
    label: 'Fire Services',
    note: 'Note that only valid data is "yes" or "no" ',
  },
  {
    value: 'fireServicesPresent',
    label: 'Fire Services Present',
    note: 'Note that only valid data is the pre-defined values of Fire Services.',
  },
  {value: 'lift', label: 'Lift', note: 'Note that only valid data is "yes" or "no"'},
  {
    value: 'liftType',
    label: 'Lift Type',
    note: 'Note that only valid data is the pre-defined values of Lift Type.',
  },
  {value: 'liftSecondary', label: 'Lift Secondary'},
  {value: 'liftSecondaryType', label: 'Lift Secondary Type'},
  {
    value: 'alarmSystem',
    label: 'Alarm System',
    note: 'Note that only valid data is "yes" or "no" ',
  },
  {
    value: 'alarmSystemSubtype',
    label: 'Alarm System Subtype',
    note: 'Note that only valid data is the pre-defined values of Alarm System Type.',
  },
  {value: 'priorRawlosRate', label: 'Prior Rawlos Rate'},
  {value: 'priorAlternateRate', label: 'Prior Alternate Rate'},
  {value: 'priorFVProfessionalFees', label: 'Prior FV Professional Fees'},
  {value: 'suburbLgaText', label: 'Suburb Lga Text'},
  {
    value: 'valuationRegion',
    label: 'Valuation Region',
    note: 'Note that only valid data is the pre-defined values of Valuation Region.',
  },
]
